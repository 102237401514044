class ErrorHandler {
  constructor(callbacks) {
    this.callbacks = callbacks;
  }

  error = {
    WRAPPER_ELEMENT_NOT_FOUND: 'Could not find the wrapper element: ',
    ELEMENT_ID_NOT_PROVIDED: "'elementId' value not provided",
    HREF_MISSING_FROM_RESPONSE: 'Could not find href in response: ',
    INCORRECT_INPUT_TYPE_FOR_INTEGRATION_TYPE:
      'Correct element type required for integration type: ',
    AUTOADDRESS_FORM_FAILED_TO_BUILD: 'Autoaddress form failed to build: ',
    AUTOADDRESS_FORM_FAILED_TO_POPULATE: 'Autoaddress form failed to populate: ',
  };

  httpError = {
    401: 'Unauthorized user, please make sure your licence key is valid.',
    402: 'Payment required.',
    403: 'Server access is forbidden.',
    404: 'The server cannot be found.',
    405: 'The api request method is not allowed.',
    409: 'Request has timed out.',
    500: 'Internal server error.',
    502: 'Bad gateway.',
    503: 'Service is unavailable.',
    504: 'Gateway timeout.',
  };

  handleError(error, value = '') {
    var errorResponse = { errorCode: 'Control Error', message: error + value };
    console.error(errorResponse);
    this.callbacks.onError(errorResponse);
  }

  handleHttpError(response) {
    var errorResponse = { errorCode: response.status, message: this.httpError[response.status] };
    this.callbacks.onError(errorResponse);
    console.error(errorResponse);
    return errorResponse;
  }
}

module.exports = { ErrorHandler };
