// creating components
export default function componentFactory(type, attributes, ...children) {
  const el = document.createElement(type);

  for (var key in attributes) {
    if (key == 'innerHTML') {
      el.innerHTML = attributes[key];
    } else if (key.startsWith('on') && typeof attributes[key] === 'function') {
      el.addEventListener(key.substring(2), attributes[key]);
    } else if (key.startsWith('data')) {
      var attributeKey = 'data-' + key.substring(4);
      el.setAttribute(attributeKey, attributes[key]);
    } else if (key == 'required') {
      el.required = attributes[key] === true;
    } else if (key == 'value') {
      el.value = attributes[key];
    } else {
      el.setAttribute(key, attributes[key]);
    }
  }

  children.forEach(child => {
    if (typeof child == 'string') {
      el.appendChild(document.createTextNode(child));
    } else {
      el.appendChild(child);
    }
  });

  return el;
}
