class Selector {
  constructor() {
    this._container = null;
  }

  getContainer() {
    return this._container;
  }

  setContainer(container) {
    this._container = container;
  }

  elementQuerySelector(selector) {
    if (this._container) {
      return this._container.querySelector(selector);
    }
  }

  elementQuerySelectorAll(selector) {
    if (this._container) {
      return this._container.querySelectorAll(selector);
    }
  }
}

export { Selector };
