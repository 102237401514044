class ApiService {
  constructor(
    apiUrl,
    version,
    token = null,
    errorHandler,
    useAuthorizationHeaderForApiKey,
    useAuthorizationHeaderForToken,
    callbacks,
    selector
  ) {
    this.apiUrl = apiUrl;
    this.token = token == null || token == 'undefined' ? null : token;
    this.callCount = 0;
    this.lastCallCount = 0;
    this.version = version;
    this.errorHandler = errorHandler;
    this.useAuthorizationHeaderForApiKey = useAuthorizationHeaderForApiKey;
    this.useAuthorizationHeaderForToken = useAuthorizationHeaderForToken;
    this.callbacks = callbacks;
    this.selector = selector;
  }

  async fetch(endpoint, params, callback, body = null, auth = null) {
    if (typeof callback !== 'function') {
      callback = function () {};
    }

    let type = endpoint;
    let preAPICallbackParams = params;
    let autoaddressButton = this.selector?.elementQuerySelector('#autoaddress-button');

    /* Rip the query params out of the link passed as params for endpoints.LINK type. 
        Use this to populate the preAPICallbackParams 
        Replace the type parameter with the endpoint it is hitting
    */
    if (endpoint === endpoints.LINK) {
      let linkUrl = new URL(params.link);
      type = this.getEndpoint(linkUrl);
      preAPICallbackParams = this.getQueryParams(linkUrl);
    }

    let onPreAPICallbackModel = {
      params: preAPICallbackParams,
      type: type,
    };

    if (this.callbacks.onPreAPI(onPreAPICallbackModel) === false) {
      return;
    }

    if (type == endpoints.SEARCH || type == 'lookup' || type == endpoints.ADDRESS_FORM_LAYOUT)
      autoaddressButton?.setAttribute('data-loading', 'true');

    let headers = null;

    const isAuthQuery = auth && typeof auth === 'string';

    // Send api key via HTTP header if useAuthorizationHeaderForApiKey and query is auth type i.e createToken
    if (this.useAuthorizationHeaderForApiKey && isAuthQuery) {
      const httpHeaders = { authorization: this.encodeKey(auth) };
      headers = new Headers(httpHeaders);
    }

    // Send token via HTTP header if useAuthorizationHeaderForToken and query uses a token
    if (this.useAuthorizationHeaderForToken && !isAuthQuery && this.token) {
      const httpHeaders = { authorization: this.encodeToken(this.token) };
      headers = new Headers(httpHeaders);
    }

    const useApiKeyQueryString = !this.useAuthorizationHeaderForApiKey && isAuthQuery && auth;

    let url = this.getUrl(endpoint, params, useApiKeyQueryString ? auth : null);

    var init = {
      method: body ? 'POST' : 'GET',
    };
    if (body) {
      init.body = JSON.stringify(body);
    }
    if (headers) {
      init.headers = headers;
    }
    this.callCount++;

    const localCallCount = this.callCount;

    let response = await fetch(url, init);
    if (response && response.ok) {
      if (type == endpoints.SEARCH || type == 'lookup' || type == endpoints.ADDRESS_FORM_LAYOUT)
        autoaddressButton?.setAttribute('data-loading', 'false');

      let responseJson = await response.json();
      let onPostAPIResponse = this.callbacks.onPostAPI(responseJson);
      responseJson = onPostAPIResponse ? onPostAPIResponse : responseJson;
      callback(responseJson, localCallCount);
    } else {
      autoaddressButton?.setAttribute('data-loading', 'false');
      this.errorHandler.handleHttpError(response);
    }
    return response;
  }

  fetchEmpty() {
    return Promise.resolve();
  }

  encodeKey(apiKey) {
    return 'Basic ' + btoa(apiKey + ':');
  }

  encodeToken(token) {
    return 'Bearer ' + token;
  }

  setToken(token) {
    this.token = token;
  }

  hasToken() {
    if (this.token) {
      return true;
    }
    return false;
  }

  getUrl(endpoint, params, apiKey = null) {
    let url = '';

    switch (endpoint) {
      case endpoints.LINK:
        url = params[endpoints.LINK];
        break;

      default:
        url = this.apiUrl + endpoint;
        break;
    }

    let query = new URLSearchParams();
    if (params) {
      for (const property in params) {
        if (Array.isArray(params[property])) {
          params[property].forEach(item => {
            query.append(property, item);
          });
        } else if (typeof params[property] === 'string') {
          query.append(property, params[property]);
        }
      }
    }

    if (this.token && !this.useAuthorizationHeaderForToken) {
      query.append('token', this.token);
    }

    if (apiKey) {
      query.append('key', apiKey);
    }

    if (this.version) {
      query.append('version', this.version);
    }

    if (query.toString() != '' && endpoint != 'link') {
      url += '?' + query.toString();
    } else {
      url += '&version=' + this.version;
    }

    return url;
  }

  getQueryParams(url) {
    let params = new URLSearchParams(url.search);
    return Object.fromEntries(params);
  }

  // Retrieving the endpoint from the URL when we have a LINK type service call
  getEndpoint(url) {
    let rootUrl = new URL(this.apiUrl);
    let pathname = url.pathname;
    let rootPathname = rootUrl.pathname;
    let endpointPath = pathname.replace(rootPathname, '');
    let parts = endpointPath.split('/').filter(Boolean);

    return parts?.[parts.length - 1];
  }
}

const endpoints = {
  AUTOCOMPLETE: 'autocomplete',
  LINK: 'link',
  FORMAT_ENTERED_ADDRESS: 'formatenteredaddress',
  ADDRESS_FORM_LAYOUT: 'autoaddressformlayout',
  JS_CONTROL_INIT: 'jscontrolinit',
  CREATE_TOKEN: 'createtoken',
  POSTCODE_POPULATE: 'postcodepopulate',
  SEARCH: 'search',
};

const linkTypes = {
  DRILLDOWN: 'drilldown',
  LOOKUP: 'lookup',
  AUTOCOMPLETE: 'autocomplete',
  SEARCH: 'search',
};

module.exports = { ApiService, endpoints, linkTypes };
