const debounce = (func, delay) => {
  let timeout;
  return (last, ...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (last != args[0]) func.apply(this, args);
    }, delay);
  };
};

module.exports = { debounce };
