function autoaddressListCutoff(list, dropdownCutoff, smViewport) {
  let autoaddressList = list.querySelector('#' + 'autoaddress-list');

  let scrollHeight = autoaddressList.scrollHeight;
  let clientHeight = autoaddressList.clientHeight;
  let listItems = autoaddressList.getElementsByTagName('li');
  let manualEntry = list.querySelector('#' + 'autoaddress-manual-enter');
  let manualEntryHeight = parseInt(window.getComputedStyle(manualEntry).height);

  let autocompleteListHeight;

  smViewport = smViewport + 'px';
  const smallDevice = window.matchMedia('(min-width: ' + smViewport + ')');

  //check if small device and limit the autocomplte list height to six lines
  if (!smallDevice.matches) {
    autocompleteListHeight = 249; //height of six lines plus the height of manual entry
    if (scrollHeight == clientHeight) {
      // if no scroll
      let elementActualHeight = parseInt(window.getComputedStyle(autoaddressList).height); // autocomplete list height
      if (elementActualHeight < autocompleteListHeight) {
        autocompleteListHeight = elementActualHeight;
      } else {
        autocompleteListHeight = autocompleteListHeight - dropdownCutoff;
      }
      autoaddressList.style.setProperty('height', autocompleteListHeight + 'px');
    }
  } else {
    autocompleteListHeight = parseInt(window.getComputedStyle(autoaddressList).height); // autocomplete list height
  }

  if (scrollHeight > clientHeight) {
    let totalListHeight = 0; //sum of visible lists in the auto-complete
    let visibleListHeight = 0;
    //loop through all the li in the ul
    for (let i = 0; i < listItems.length; ++i) {
      let listHeight = parseInt(window.getComputedStyle(listItems[i]).height); // height of individual li elements

      if (totalListHeight + listHeight < autocompleteListHeight - manualEntryHeight) {
        visibleListHeight = totalListHeight; // store the total height of visible li elements
      } else {
        break;
      }
      totalListHeight = totalListHeight + listHeight; // sum of heights of all the li elements
    }
    let newHeight = visibleListHeight + dropdownCutoff + manualEntryHeight;
    autoaddressList.style.setProperty('height', newHeight + 'px');
  }

  return list;
}

module.exports = { autoaddressListCutoff };
