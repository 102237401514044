import componentFactory from './component-factory';
import Utils from './helpers/utils';
import { endpoints } from './services/api-service';

class AddressFormBuilder {
  constructor(callbacks, apiService) {
    this._callbacks = callbacks;
    this._apiService = apiService;
    this.resizeTextArea = () => {};
  }

  get callbacks() {
    return this._callbacks;
  }

  set callbacks(val) {
    this._callbacks = val;
  }

  get apiService() {
    return this._apiService;
  }

  autoaddressForm;
  isValid = true;

  createAddressForm(response, selectedCountry, settings, lookupResponse = null, options) {
    this.setResizeTextAreaCallback(settings.inputHeight);

    let formComponentList = [];

    let countryContainer = this.createCountryDropdownField(response.countries, selectedCountry);

    formComponentList.push(wrapWithRow([wrapWithCol(countryContainer)]));

    let labelStructure = response.formLayout.labelStructure.split(/,|_/);

    if (!labelStructure.includes('addresslines') || !labelStructure.includes('organization')) {
      response.address?.lines.forEach(line => {
        let addressLine = this.createAddressLine(line);
        formComponentList.push(wrapWithRow([wrapWithCol(addressLine)]));
      });
    }

    this.createFormattedRowsWithSize(response, formComponentList, lookupResponse);

    let formManualEntry = componentFactory(
      'div',
      {
        id: 'autoaddress-manual-entry-form',
        class: 'autoaddress-manual-entry-form',
      },
      ...formComponentList
    );

    appendValidationWrapperToForm(formManualEntry);

    let handleFormChange = (form, event) => {
      if (event.target.id !== 'manual-form-country') {
        if (options.validateOnChange) {
          this.validateForm(form, event);
        }
        let params = this.getFormFieldParamters(form);
        // Legacy support for onPostLookup behaviour, only auto trigger FormatEnteredAddress calls if onPostLookup is set
        if (options.onPostLookup) {
          this.callbacks.onSubmitToFormatEnteredAddress(params);
        }
        this.callbacks.onAddressFormChange(params);
      }
    };

    let eventTypes = ['change', 'focusout', 'paste', 'input'];

    eventTypes.forEach(eventType => {
      formManualEntry.addEventListener(eventType, event => {
        settings.hasFormValuesChanged = true;
        handleFormChange(formManualEntry, event);
      });
    });

    this.autoaddressForm = formManualEntry;

    let addressForm = componentFactory(
      'div',
      {
        id: 'autoaddress-form-container',
        class: 'autoaddress-form-container',
      },
      formManualEntry
    );

    return addressForm;
  }

  createFormattedRowsWithSize(response, formComponentList, lookupResponse) {
    // "formStructure": [
    // 	    {"sm": 12, "md": 12, "lg": 4},
    // 	    {"sm": 12, "md": 7, "lg": 5},
    // 	    {"sm": 12, "md": 5, "lg": 3}
    // 	]

    let formElementStructures = response?.formLayout?.formStructure;

    let labelStructure = response?.formLayout?.labelStructure;
    let fields = labelStructure.split(/,|_/); // region_city,postcode -> ["region, city, postcode"]
    let addressLines = response.address?.lines;
    let formStructureIndex = 0;
    let colItems = [];
    let organization = addressLines.find(line => line.key == 'Organization');
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      let colField, label;
      let inputContainer;
      let colElements = [];
      if (field == 'organization' && organization !== undefined) {
        colField = this.getManualEntryFieldByType(field, response, lookupResponse, organization);
        label = getManualEntryLabelByType(field, response, organization);
        if (colField != null) {
          if (label != null) {
            colElements.push(label);
          }
          colElements.push(colField);
          inputContainer = componentFactory(
            'div',
            {
              id: field + '-container',
            },
            ...colElements
          );
          colItems.push(wrapWithCol(inputContainer, null));
          colElements = [];
          inputContainer = '';
        }
      }
      if (field == 'addresslines' && field !== 'organization') {
        addressLines.forEach(line => {
          if (line.key !== 'Organization') {
            colField = this.getManualEntryFieldByType(field, response, lookupResponse, line);
            label = getManualEntryLabelByType(field, response, line);
            if (colField != null) {
              if (label != null) {
                colElements.push(label);
              }
              colElements.push(colField);
              inputContainer = componentFactory(
                'div',
                {
                  id: field + '-container',
                },
                ...colElements
              );
              colItems.push(wrapWithCol(inputContainer, null));
              colElements = [];
              inputContainer = '';
            }
          }
        });
      }
      if (field !== 'addresslines' && field !== 'organization') {
        colField = this.getManualEntryFieldByType(field, response, lookupResponse, null);
        label = getManualEntryLabelByType(field, response, null);
        if (colField != null) {
          if (label != null) {
            colElements.push(label);
          }
          colElements.push(colField);
          inputContainer = componentFactory(
            'div',
            {
              id: field + '-container',
            },
            ...colElements
          );
          colItems.push(wrapWithCol(inputContainer, formElementStructures[formStructureIndex]));
        }
        formStructureIndex++;
      }
    }
    formComponentList.push(wrapWithRow(colItems));
  }

  createAddressLine(line) {
    let addressTopDiv = componentFactory('div', {
      class: 'top-floating-div',
    });
    let addressBottomDiv = componentFactory('div', {
      class: 'bottom-floating-div',
    });
    let addressLabel = componentFactory('label', {
      class: 'enter-manual-label',
      for: 'manual-form-' + line.key,
      innerHTML: line.required == false ? line.label : line.label + ' *',
    });

    let addressLabelDiv = componentFactory(
      'div',
      {
        class: 'floating-label',
      },
      addressTopDiv,
      addressBottomDiv,
      addressLabel
    );

    let inputElement = componentFactory('textarea', {
      placeholder: line.hint,
      class: 'enter-manual-text-area autoaddress-textarea',
      required: line.required,
      spellcheck: false,
      id: 'manual-form-' + line.key,
      name: line.label,
      maxlength: line.width,
      value: line.value ?? '',
      oninput: this.resizeTextArea,
    });

    let lineContainer = componentFactory(
      'div',
      {
        id: line.key + '-container',
      },
      addressLabelDiv,
      inputElement
    );

    return lineContainer;
  }

  getFormFieldParamters(form) {
    let params = {};
    let addressLines = [];
    const elements = Utils.getElements(form);

    for (let index = 0; index < elements.length; index++) {
      const formElement = elements[index];
      if (formElement.id == 'manual-form-Organization') {
        params = { ...params, organization: form.querySelector('#' + formElement.id).value };
      }
      if (formElement.id.startsWith('manual-form-AddressLine')) {
        addressLines.push(form.querySelector('#' + formElement.id).value);
      }
      params = { ...params, addressLine: addressLines };
    }

    params.country = form.querySelector('#' + 'manual-form-country').value;
    params.city = form.querySelector('#' + 'manual-form-city')
      ? form.querySelector('#' + 'manual-form-city').value
      : '';

    let regionElement = form.querySelector('#' + 'manual-form-region');
    if (regionElement?.type == 'select-one') {
      let elementOptions = [];
      let i = 0,
        length = regionElement.options.length;

      while (i < length) {
        elementOptions.push({
          key: regionElement.options[i].value,
          label: regionElement.options[i].text,
        });
        i++;
      }

      params.region = elementOptions.some(o => o.key == regionElement.value)
        ? elementOptions.find(o => o.key == regionElement.value).label
        : '';
    } else {
      params.region = form.querySelector('#' + 'manual-form-region')
        ? form.querySelector('#' + 'manual-form-region').value
        : '';
    }

    params.postcode = form.querySelector('#' + 'manual-form-postcode')
      ? form.querySelector('#' + 'manual-form-postcode').value
      : '';

    return params;
  }

  // when an input field is interacted with we need to prevent newline
  // we need to resize the field if pasted text overflows
  // we set the function to a class variable because we need the input height from the settings object
  setResizeTextAreaCallback(inputHeight) {
    this.resizeTextArea = event => {
      let field = event.target;
      field.value = field.value.replaceAll('\n', '');

      if (event.inputType != 'insertLineBreak') {
        field.style.height = 0;
        field.style.height = Math.max(field.scrollHeight, inputHeight) + 'px';
      }
    };
  }

  createCountryDropdownField(countries, selectedCountry) {
    let optionCountryList = [];
    countries.forEach(country => {
      country.links.forEach(link => {
        optionCountryList.push(
          componentFactory('option', {
            innerHTML: country.label,
            value: country.key,
            datalink: link.href,
          })
        );
      });
    });

    optionCountryList.sort(sortOptionsFunction);

    let countryTopDiv = componentFactory('div', {
      class: 'top-floating-div',
    });
    let countryBottomDiv = componentFactory('div', {
      class: 'bottom-floating-div',
    });
    let countryLabel = componentFactory('label', {
      class: 'enter-manual-label',
      for: 'manual-form-country',
      innerHTML: 'Country',
    });

    let countrylabelDiv = componentFactory(
      'div',
      {
        class: 'floating-label',
      },
      countryTopDiv,
      countryBottomDiv,
      countryLabel
    );

    let countrySelect = componentFactory(
      'select',
      {
        id: 'manual-form-country',
        name: 'Country',
        class: 'drp-down-select',
      },
      ...optionCountryList
    );

    let countryContainer = componentFactory(
      'div',
      {
        id: 'country-container',
      },
      countrylabelDiv,
      countrySelect
    );

    if (selectedCountry != '') countrySelect.value = selectedCountry;

    let onSelectCountry = this.callbacks.onSelectCountry;
    let buildAddressFormFromLink = this.callbacks.onRebuildAddressForm;
    countrySelect.addEventListener('change', function () {
      if (onSelectCountry(countrySelect.value) !== false) {
        buildAddressFormFromLink(
          countrySelect.options[countrySelect.selectedIndex].getAttribute('data-link')
        );
      }
    });
    return countryContainer;
  }

  getManualEntryFieldByType(fieldType, response, lookupResponse = null, line) {
    switch (fieldType) {
      case 'city':
        return componentFactory('textarea', {
          placeholder: response.address?.city?.hint,
          class: 'enter-manual-text-area autoaddress-textarea',
          required: response.address?.city?.required,
          id: 'manual-form-city',
          spellcheck: false,
          name: response.address?.city?.label,
          maxlength: response.address?.city?.width,
          oninput: this.resizeTextArea,
          value: response.address?.city?.value ?? '',
        });
      case 'region': {
        let regionOptions = response.address?.region?.options;
        let buildOptionList = lookupResponse == null;
        let chosenOptionKey = '';

        if (regionOptions.length != 0 && !buildOptionList) {
          var chosenRegion = response.address?.region?.value;
          var regionOption = regionOptions.find(
            r => r.key == chosenRegion || r.label == chosenRegion
          );
          buildOptionList = regionOption != undefined;
          chosenOptionKey = buildOptionList ? regionOption.key : '';
        }

        if (buildOptionList) {
          let optionRegionList = [];
          optionRegionList.push(
            componentFactory('option', {
              innerHTML: response.address?.region?.hint,
              value: '',
              hidden: true,
              selected: true,
              disabled: true,
            })
          );

          response.address?.region?.options.forEach(region => {
            optionRegionList.push(
              componentFactory('option', {
                innerHTML: region.label,
                value: region.key,
              })
            );
          });
          var regionSelect = componentFactory(
            'select',
            {
              id: 'manual-form-region',
              name: response.address?.region?.label,
              class: 'enter-manual-text',
              required: response.address?.region?.required,
              maxlength: response.address?.region?.width,
            },
            ...optionRegionList
          );
          regionSelect.value = chosenOptionKey;
          return regionSelect;
        } else {
          return componentFactory('textarea', {
            placeholder: response.address?.region?.hint,
            class: 'enter-manual-text-area autoaddress-textarea',
            required: response.address?.region?.required,
            id: 'manual-form-region',
            spellcheck: false,
            name: response.address?.region?.label,
            maxlength: response.address?.region?.width,
            value: response.address?.region?.value ?? '',
            oninput: this.resizeTextArea,
          });
        }
      }
      case 'postcode': {
        // Check if the response has the postcode populate link
        const postCodePopulateHref = Utils.getHrefFromResponse(
          response,
          endpoints.POSTCODE_POPULATE
        );
        const isPostCodePopulateEnabled = !!postCodePopulateHref;
        return componentFactory('textarea', {
          placeholder: response.address?.postcode?.hint,
          class: 'enter-manual-text-area autoaddress-textarea',
          required: response.address?.postcode?.required,
          id: 'manual-form-postcode',
          name: response.address?.postcode?.label,
          spellcheck: false,
          maxlength: response.address?.postcode?.width,
          pattern: response.address?.postcode.regex == null ? '' : response.address?.postcode.regex,
          value: response.address?.postcode?.value ?? '',
          oninput: event => {
            this.resizeTextArea(event);
            if (isPostCodePopulateEnabled) {
              this.postCodeAutofill(event, postCodePopulateHref);
            }
          },
        });
      }
      case 'addresslines':
        return componentFactory('textarea', {
          placeholder: line.hint,
          class: 'enter-manual-text-area autoaddress-textarea',
          required: line.required,
          spellcheck: false,
          id: 'manual-form-' + line.key,
          name: line.label,
          maxlength: line.width,
          value: line.value ?? '',
          oninput: this.resizeTextArea,
        });
      case 'organization':
        return componentFactory('textarea', {
          placeholder: line.hint,
          class: 'enter-manual-text-area autoaddress-textarea',
          required: line.required,
          spellcheck: false,
          id: 'manual-form-' + line.key,
          name: line.label,
          maxlength: line.width,
          value: line.value ?? '',
          oninput: this.resizeTextArea,
        });
      case 'empty':
        return componentFactory('div', {
          class: 'empty-div',
        });

      default:
        return null;
    }
  }

  postCodeAutofill(event, postCodePopulateHref) {
    const { value } = event.target;
    const pattern = event.target.getAttribute('pattern');
    const regex = new RegExp(pattern, 'i');
    const isValid = regex.test(value);

    // If the value is valid, we can make the request to the populate endpoint
    if (isValid) {
      let params = {
        // Replace the addressId with the value of the postcode
        link: postCodePopulateHref?.replace('addressId=POSTCODE', `addressId=US${value}`),
      };
      this.apiService.fetch(endpoints.LINK, params, response => {
        const { address } = response;

        // Populate the form with the address response
        this.populateFormWithAddress(address);
      });
    }
  }

  populateFormWithAddress(address) {
    const form = this.autoaddressForm;
    const elements = Utils.getElements(form);

    for (let index = 0; index < elements.length; index++) {
      const formElement = elements[index];
      const key = formElement?.id?.replace('manual-form-', '') ?? '';
      // Check if the key is a valid key in the address object
      let value = address?.[key]?.value ?? '';
      // Check if it's address line
      if (key.startsWith('AddressLine') || key === 'Organization') {
        const line = address?.lines?.find(l => l.key === key);
        value = line?.value ?? '';
      }

      if (value) {
        // Detect if the form element is a select or textarea
        const options = formElement.options ? Array.from(formElement.options) : null;
        formElement.value = options ? options.find(o => o.text === value)?.value : value;
      }
    }
  }

  validateForm(form, event) {
    const elements = Utils.getElements(form);
    let validationWarningsWrapper = form.querySelector('#autoaddress-form-validation-warnings');
    for (let index = 0; index < elements.length; index++) {
      const formElement = elements[index];
      if (formElement.hasAttribute('required') && formElement.value == '') {
        if (formElement == event.target) {
          validationWarningsWrapper
            .querySelector('#error-' + formElement.id)
            ?.classList.add('autoaddress-show');
          formElement.classList.add('autoaddress-invalid');
        }
      } else {
        validationWarningsWrapper
          .querySelector('#error-' + formElement.id)
          ?.classList.remove('autoaddress-show');
        formElement.classList.remove('autoaddress-invalid');
      }
      // Validating postcode
      if (formElement.hasAttribute('pattern')) {
        let regexValue = formElement.getAttribute('pattern');
        let actualRegex = new RegExp(regexValue, 'i');

        if (!actualRegex.test(formElement.value) && formElement.value !== '') {
          if (formElement == event.target) {
            validationWarningsWrapper
              .querySelector('#error-' + formElement.id + '-validation')
              ?.classList.add('autoaddress-show');
            formElement.classList.add('autoaddress-incorrect');
          }
        } else {
          validationWarningsWrapper
            .querySelector('#error-' + formElement.id + '-validation')
            ?.classList.remove('autoaddress-show');
          formElement.classList.remove('autoaddress-incorrect');
        }
      }
    }
  }

  validateAutoaddressForm() {
    let errorMessage = {};
    if (!this.autoaddressForm) {
      errorMessage['searchBox'] = 'Address is required.';
    } else {
      const elements = Utils.getElements(this.autoaddressForm);
      for (let index = 0; index < elements.length; index++) {
        const formElement = elements[index];
        if (formElement.hasAttribute('required') && formElement.value == '') {
          let formElementId = formElement.id;
          let key = formElementId.substring(formElementId.lastIndexOf('-') + 1);
          errorMessage[key] = formElement.name + ' is required.';
        }
        //validating postcode
        if (formElement.hasAttribute('pattern')) {
          let regexValue = formElement.getAttribute('pattern');
          let actualRegex = new RegExp(regexValue, 'i');
          if (!actualRegex.test(formElement.value) && formElement.value !== '') {
            let formElementId = formElement.id;
            let key = formElementId.substring(formElementId.lastIndexOf('-') + 1);
            errorMessage[key] = formElement.name + ' is invalid.';
            break;
          }
        }
      }
    }
    if (Object.keys(errorMessage).length === 0) {
      errorMessage = null;
    }
    return errorMessage;
  }
}

function appendValidationWrapperToForm(form) {
  const elements = Utils.getElements(form);
  let formValidationWarnings = [];
  for (let index = 0; index < elements.length; index++) {
    const formElement = elements[index];
    formValidationWarnings.push(
      componentFactory('p', {
        innerHTML: (formElement.name ?? formElement.id) + ' is required!',
        id: 'error-' + formElement.id,
        class: 'autoaddress-error-text',
      })
    );
    if (formElement.id === 'manual-form-postcode') {
      formValidationWarnings.push(
        componentFactory('p', {
          innerHTML: (formElement.name ?? formElement.id) + ' is invalid!',
          id: 'error-' + formElement.id + '-validation',
          class: 'autoaddress-error-text',
        })
      );
    }
  }
  let validationWarningsWrapper = componentFactory(
    'div',
    {
      id: 'autoaddress-form-validation-warnings',
    },
    ...formValidationWarnings
  );
  form.appendChild(validationWarningsWrapper);
}

function getManualEntryLabelByType(fieldType, response, line) {
  let topDiv = componentFactory('div', {
    class: 'top-floating-div',
  });
  let bottomDiv = componentFactory('div', {
    class: 'bottom-floating-div',
  });
  let label;
  switch (fieldType) {
    case 'city':
      label = componentFactory('label', {
        class: 'enter-manual-label',
        for: 'manual-form-city',
        innerHTML:
          response.address?.city?.required == false
            ? response.address?.city?.label
            : response.address?.city?.label + ' *',
      });
      return componentFactory(
        'div',
        {
          class: 'floating-label',
        },
        topDiv,
        bottomDiv,
        label
      );

    case 'region':
      label = componentFactory('label', {
        class: 'enter-manual-label',
        for: 'manual-form-region',
        innerHTML:
          response.address?.region?.required == false
            ? response.address?.region?.label
            : response.address?.region?.label + ' *',
      });
      return componentFactory(
        'div',
        {
          class: 'floating-label',
        },
        topDiv,
        bottomDiv,
        label
      );

    case 'postcode':
      label = componentFactory('label', {
        class: 'enter-manual-label',
        for: 'manual-form-postcode',
        innerHTML:
          response.address?.postcode?.required == false
            ? response.address?.postcode?.label
            : response.address?.postcode?.label + ' *',
      });
      return componentFactory(
        'div',
        {
          class: 'floating-label',
        },
        topDiv,
        bottomDiv,
        label
      );
    case 'addresslines':
      label = componentFactory('label', {
        class: 'enter-manual-label',
        for: 'manual-form-' + line.key,
        innerHTML: line.required == false ? line.label : line.label + ' *',
      });
      return componentFactory(
        'div',
        {
          class: 'floating-label',
        },
        topDiv,
        bottomDiv,
        label
      );
    case 'organization':
      label = componentFactory('label', {
        class: 'enter-manual-label',
        for: 'manual-form-' + line.key,
        innerHTML: line.required == false ? line.label : line.label + ' *',
      });
      return componentFactory(
        'div',
        {
          class: 'floating-label',
        },
        topDiv,
        bottomDiv,
        label
      );
    default:
      return null;
  }
}

function wrapWithCol(elementToWrap, formElementStructure = null) {
  let colClass = 'autoaddress-col';
  if (formElementStructure) {
    colClass =
      ' autoaddress-col-sm-' +
      formElementStructure.sm +
      ' autoaddress-col-md-' +
      formElementStructure.md +
      ' autoaddress-col-lg-' +
      formElementStructure.lg;
  }
  return componentFactory(
    'div',
    {
      class: colClass,
    },
    elementToWrap
  );
}

function wrapWithRow(elementsToWrap) {
  return componentFactory(
    'div',
    {
      class: 'autoaddress-row',
    },
    ...elementsToWrap
  );
}

function sortOptionsFunction(a, b) {
  return a.innerHTML.localeCompare(b.innerHTML);
}

export { AddressFormBuilder };
