class Callbacks {
  constructor(options) {
    if (options.onSelectItem && typeof options.onSelectItem === 'function') {
      this.onSelectItem = options.onSelectItem;
    }
    if (options.onPostInit && typeof options.onPostInit === 'function') {
      this.onPostInit = options.onPostInit;
    }
    if (options.onPostJsControlInit && typeof options.onPostJsControlInit === 'function') {
      this.onPostJsControlInit = options.onPostJsControlInit;
    }
    if (options.onSelectItemResponse && typeof options.onSelectItemResponse === 'function') {
      this.onSelectItemResponse = options.onSelectItemResponse;
    }
    if (
      options.onSelectEnterAddressManually &&
      typeof options.onSelectEnterAddressManually === 'function'
    ) {
      this.onSelectEnterAddressManually = options.onSelectEnterAddressManually;
    }
    if (options.onSelectCountry && typeof options.onSelectCountry === 'function') {
      this.onSelectCountry = options.onSelectCountry;
    }
    if (options.onClickSearchButton && typeof options.onClickSearchButton === 'function') {
      this.onClickSearchButton = options.onClickSearchButton;
    }
    if (options.onClickInputField && typeof options.onClickInputField === 'function') {
      this.onClickInputField = options.onClickInputField;
    }
    if (options.onShowSearchBar && typeof options.onShowSearchBar === 'function') {
      this.onShowSearchBar = options.onShowSearchBar;
    }
    if (options.onHideSearchBar && typeof options.onHideSearchBar === 'function') {
      this.onHideSearchBar = options.onHideSearchBar;
    }
    if (options.onShowCustomerFields && typeof options.onShowCustomerFields === 'function') {
      this.onShowCustomerFields = options.onShowCustomerFields;
    }
    if (options.onHideCustomerFields && typeof options.onHideCustomerFields === 'function') {
      this.onHideCustomerFields = options.onHideCustomerFields;
    }
    if (options.onPreSetPlaceholderText && typeof options.onPreSetPlaceholderText === 'function') {
      this.onPreSetPlaceholderText = options.onPreSetPlaceholderText;
    }
    if (
      options.onPostSetPlaceholderText &&
      typeof options.onPostSetPlaceholderText === 'function'
    ) {
      this.onPostSetPlaceholderText = options.onPostSetPlaceholderText;
    }
    if (options.onError && typeof options.onError === 'function') {
      this.onError = options.onError;
    }
    if (options.onAddressFormChange && typeof options.onAddressFormChange === 'function') {
      this.onAddressFormChange = options.onAddressFormChange;
    }
    if (options.onPreAPI && typeof options.onPreAPI === 'function') {
      this.onPreAPI = options.onPreAPI;
    }
    if (options.onPostAPI && typeof options.onPostAPI === 'function') {
      this.onPostAPI = options.onPostAPI;
    }
    if (options.onAddressResult && typeof options.onAddressResult === 'function') {
      this.onAddressResult = options.onAddressResult;
    }

    /* Deprecated Callbacks 
                Deprecation messages to be uncommented when documentation has info on new Callback methods
        */
    if (options.onPreAutocomplete && typeof options.onPreAutocomplete === 'function') {
      //console.warn(this.deprecationMessage("onPreAutocomplete","onPreAPI"));
      this.onPreAutocomplete = options.onPreAutocomplete;
    }
    if (options.onPostAutocomplete && typeof options.onPostAutocomplete === 'function') {
      //console.warn(this.deprecationMessage("onPostAutocomplete","onPostAPI"));
      this.onPostAutocomplete = options.onPostAutocomplete;
    }
    if (options.onPreLookup && typeof options.onPreLookup === 'function') {
      //console.warn(this.deprecationMessage("onPreLookup","onPreAPI"));
      this.onPreLookup = options.onPreLookup;
    }
    if (options.onPostLookup && typeof options.onPostLookup === 'function') {
      //console.warn(this.deprecationMessage("onPostLookup","onAddressResult"));
      this.onPostLookup = options.onPostLookup;
    }
    if (options.onPreSearch && typeof options.onPreSearch === 'function') {
      //console.warn(this.deprecationMessage("onPreSearch","onPreAPI"));
      this.onPreSearch = options.onPreSearch;
    }
    if (options.onPostSearch && typeof options.onPostSearch === 'function') {
      //console.warn(this.deprecationMessage("onPostSearch","onPostAPI"));
      this.onPostSearch = options.onPostSearch;
    }
    if (
      options.onPreAutoaddressFormLayout &&
      typeof options.onPreAutoaddressFormLayout === 'function'
    ) {
      //console.warn(this.deprecationMessage("onPreAutoaddressFormLayout","onPreAPI"));
      this.onPreAutoaddressFormLayout = options.onPreAutoaddressFormLayout;
    }
    if (
      options.onPostAutoaddressFormLayout &&
      typeof options.onPostAutoaddressFormLayout === 'function'
    ) {
      //console.warn(this.deprecationMessage("onPostAutoaddressFormLayout","onPostAPI"));
      this.onPostAutoaddressFormLayout = options.onPostAutoaddressFormLayout;
    }
    if (
      options.onPreFormatEnteredAddress &&
      typeof options.onPreFormatEnteredAddress === 'function'
    ) {
      //console.warn(this.deprecationMessage("onPreFormatEnteredAddress","onPreAPI"));
      this.onPreFormatEnteredAddress = options.onPreFormatEnteredAddress;
    }
    if (
      options.onPostFormatEnteredAddress &&
      typeof options.onPostFormatEnteredAddress === 'function'
    ) {
      //console.warn(this.deprecationMessage("onPostFormatEnteredAddress","onPostAPI"));
      this.onPostFormatEnteredAddress = options.onPostFormatEnteredAddress;
    }
  }

  deprecationMessage = function (method, replacement) {
    return `Autoaddress Control: ${method} has been deprecated. Please check our documentation for ${replacement} usage: https://docs.autoaddress.com/help/javascript-callback`;
  };

  onPreAutocomplete = function (value) {
    return true;
  };
  onPostAutocomplete = function (response) {
    return response;
  };

  onSelectItem = function (value) {
    return true;
  };
  onSelectItemResponse = function (response) {
    return response;
  };

  onPreLookup = function (value) {
    return true;
  };

  onPostLookup = function (response) {
    return response;
  };

  onSelectEnterAddressManually = function () {
    return true;
  };

  onPreFormatEnteredAddress = function (value) {
    return true;
  };

  onPostFormatEnteredAddress = function (response) {
    return response;
  };

  onSelectCountry = function (value) {
    return true;
  };

  onClickSearchButton = function (value) {
    return true;
  };
  onPreSearch = function (response) {
    return response;
  };
  onPostSearch = function (response) {
    return response;
  };

  onClickInputField = function (value) {
    return true;
  };

  onPostInit = function (value) {
    return true;
  };

  onShowSearchBar = function (value) {
    return true;
  };

  onHideSearchBar = function (value) {
    return true;
  };

  onShowCustomerFields = function (value) {
    return true;
  };

  onHideCustomerFields = function (value) {
    return false;
  };

  onPreAutoaddressFormLayout = function (value) {
    return true;
  };

  onPreAPI = function (value) {
    return true;
  };

  onPostAPI = function (response) {
    return response;
  };

  onPostAutoaddressFormLayout = function (response) {
    return response;
  };

  onPreSetPlaceholderText = function (value) {
    return true;
  };

  onPostSetPlaceholderText = function (response) {
    return response;
  };

  onError = function (response) {
    return response;
  };

  // Internal
  onPostJsInit = function (value) {
    return true;
  };

  onPostJsControlInit = function (response) {
    return response;
  };

  onRebuildAddressForm(link) {
    return link;
  }

  onSubmitToFormatEnteredAddress(params) {
    return params;
  }

  onTokenReceived() {
    return true;
  }

  onAddressFormChange(params) {
    return params;
  }

  onAddressResult = function (response) {
    return response;
  };
}

export { Callbacks };
